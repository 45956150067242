<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <form class="needs-validation" @submit.prevent="Form">
              <div class="card-body">
                <h4 class="card-title"></h4>

                <div class="row mt-2">
                  <div class="row">
                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="subjectCode">เลขที่หัวข้อการตรวจ:</label>
                        <input
                          v-model="form.subjectCode"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="nameTh">หัวข้อ(ไทย):</label>
                        <input
                          v-model="form.nameTh"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.nameTh.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.nameTh.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltiplame"
                          >หัวข้อ(อังกฤษ):</label
                        >
                        <input
                          v-model="form.nameEn"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="type">ประเภท:</label>
                        <multiselect
                          id="type"
                          v-model="form.type"
                          :options="typeOption"
                          :show-labels="false"
                          :class="{
                            'is-invalid': submitform && $v.form.type.$error,
                          }"
                          label="nameTh"
                          track-by="nameTh"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >

                        <div
                          v-if="submitform && $v.form.type.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.type.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="form.manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3"
                          checked
                          plain
                          >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-success">
                    บันทึกการแก้ไข
                  </button>
                  &nbsp;
                </div>
              </div>
            </form>

            <hr />
          </div>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">รูปตัวอย่าง</th>
                      <th scope="col">รายละเอียด(ไทย)</th>
                      <th scope="col">รายละเอียด(อังกฤษ)</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colspan="4">
                        <b-button
                          class="badge bg-primary font-size-13 ms-2 float-end"
                          size="sm"
                          v-b-modal.modalDetail
                        >
                          <i class="uil uil-plus"></i>
                          เพิ่มการตรวจเช็ค
                        </b-button>
                      </th>
                    </tr>
                    <tr v-for="(item, index) in rowDataRule" :key="index">
                      <th scope="row" class="text-center">
                        <div
                          @click="getDataloadImage(item)"
                          class="text-center popup-gallery"
                        >
                          <img
                            :src="item.thumbnailPath"
                            @click="singleImage = true"
                            class="img-fluid"
                          />
                        </div>
                        <vue-easy-lightbox
                          :visible="singleImage"
                          :imgs="imagePath"
                          @hide="singleImage = false"
                        ></vue-easy-lightbox
                        ><br />
                        <b-button
                          @click="showModal(item)"
                          class="font-size-13 ms-2"
                          size="sm"
                          variant="primary"
                          >อัพโหลดรูป</b-button
                        >
                      </th>
                      <td>
                        <input
                          v-model="item.nameTh"
                          class="form-control"
                          maxlength="100"
                          @change="putRule(item)"
                        />
                      </td>
                      <td>
                        <input
                          v-model="item.nameEn"
                          class="form-control"
                          maxlength="100"
                          @change="putRule(item)"
                        />
                      </td>
                      <td class="text-center">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alertRule(item, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row"><h5>สาขาที่สามารถใช้งานได้</h5></div>
              <hr />

              <div class="row">
                <div class="col-6">
                  <label for="tb"><b>เลือกสาขาเพื่อใช้งาน</b></label>
                  <b-button
                    class="badge bg-success font-size-12 ms-2"
                    size="sm"
                    @click="addAllBranch()"
                  >
                    <i class="uil uil-plus"></i>เพิ่มทั้งหมด
                  </b-button>
                  <b-table
                    :items="branchMasterSelect"
                    :fields="fieldsMaster"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchDfi">
                      {{ rowsBranchDfi.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchDfi">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        title="Add"
                        @click="AddBranch(rowsBranchDfi.item)"
                      >
                        <i class="uil uil-plus font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>

                <!-- //! appv branch-->

                <div class="col-6">
                  <label for="tb"><b>สาขาที่ใข้งานได้</b></label>
                  <b-button
                    class="badge bg-danger font-size-12 ms-2"
                    size="sm"
                    @click="delAllBranch()"
                  >
                    <i class="uil uil-trash"></i> นำออกทั้งหมด
                  </b-button>

                  <b-table
                    :items="rowsBranchDfi"
                    :fields="fieldsSelect"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchDfi">
                      {{ rowsBranchDfi.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchDfi">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="delBranch(rowsBranchDfi.item)"
                      >
                        <i class="uil uil-trash font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <b-modal
          ref="modalUploadImg"
          :id="`modalUploadImg-${ruleId}`"
          :title="`อัพโหลดรูป - ${this.nameTh}`"
          hide-footer
          size="l"
          centered
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-9 col-sm-12 col-9">
                <div class="position-relative">
                  <b-form-file
                    type="file"
                    ref="img"
                    id="img"
                    v-model="image"
                    accept="image/*"
                    class="form-control"
                    plain
                    @input="uploadRule(ruleId)"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          ref="modalDetail"
          id="modalDetail"
          title="เพิ่มการตรวจเช็ค"
          hide-footer
          size="lg"
          centered
        >
          <form class="needs-validation" @submit.prevent="FormDetail">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="position-relative">
                    <code>* </code><label for="">รายละเอียด(ไทย)</label>
                    <input
                      v-model="formDetail.nameTh"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitformDetail && $v.formDetail.nameTh.$error,
                      }"
                    />
                    <div
                      v-if="submitformDetail && $v.formDetail.nameTh.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formDetail.nameTh.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltiplame"
                      >รายละเอียด(อังกฤษ):</label
                    >
                    <input
                      v-model="formDetail.nameEn"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-success">บันทึก</button>
                &nbsp;
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.auditSubject,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      overlayFlag: false,
      enabled: false,
      submitform: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",

      title: "แก้ไขข้อการตรวจมาตรฐาน",
      items: [
        {
          text: "ตรวจมาตรฐาน",
          active: true,
        },
        {
          text: appConfig.auditSubject,
          href: "/dealer-audit-subject",
        },
        {
          text: "แก้ไขข้อการตรวจมาตรฐาน",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      imagePath: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      typeOption: [{ nameTh: "หลังการขาย", id: "SALE" }],
      form: {
        manageBranch: "",
        subjectCode: "",
        type: "",
        nameTh: "",
        nameEn: "",
      },
      formDetail: {
        nameTh: "",
        nameEn: "",
      },
      fieldsMaster: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "nameTh",
          label: "ชื่อสาขา",
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      fieldsSelect: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "branchNameTh",
          label: "ชื่อสาขาที่ใช้งานได้",
        },
        {
          key: "action",
          label: "นำออก",
        },
      ],
      singleImage: false,
      rowDataRule: [],
      branchMasterSelect: [],
      rowsBranchDfi: [],
      provinceId: "",
      amphurId: "",
      districtId: "",
      submitformDetail: false,
      rule: {
        nameTh: "",
        nameEn: "",
      },
      ruleId: "",
      nameTh: "",
      image: null,
    };
  },
  validations: {
    form: {
      nameTh: {
        required,
      },

      type: {
        required,
      },
    },
    formDetail: {
      nameTh: {
        required,
      },
    },
  },
  mounted() {},
  computed: {},
  created() {
    this.showDfi();
    this.getlocalData();
  },
  methods: {
    showImg(data, index) {
      this.index = index;
      this.singleImage = true;
      console.log(data);
      // this.getDataloadImage(data);
    },
    getDataloadImage: function (data) {
      this.overlayFlag = true;

      // this.fileId = data.fileId;

      useNetw
        .get("api/d-audit-subject/full-size-image", {
          params: {
            subjectId: atob(this.$route.params.subjectId),
            ruleId: data.ruleId,
            fileId: data.fileId,
          },
        })
        .then((response) => {
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    handleHide() {
      this.singleImage = false;
    },
    addform() {
      this.rowDataRule.push({
        nameTh: "",
      });
      this.postRule();
    },
    postRule: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/d-audit-subject/rule/store", {
          subjectId: atob(this.$route.params.subjectId),
          nameTh: this.formDetail.nameTh,
          nameEn: this.formDetail.nameEn,
        })
        .then((response) => {
          this.$refs.modalDetail.hide();
          this.showAlert(response.data.message);
          this.getRule();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    putRule: function (item) {
      this.overlayFlag = true;

      useNetw
        .put("api/d-audit-subject/rule/update", {
          subjectId: atob(this.$route.params.subjectId),
          ruleId: item.ruleId,
          nameTh: item.nameTh,
          nameEn: item.nameEn,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getRule();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    uploadRule: function (ruleId) {
      const bodyFormData = new FormData();
      this.overlayFlag = true;
      const imagefile = document.querySelector("#img");
      bodyFormData.append("subjectId", atob(this.$route.params.subjectId));
      bodyFormData.append("ruleId", ruleId);

      bodyFormData.append(
        "imgFile",
        imagefile.files[0] ? imagefile.files[0] : ""
      );
      useNetw
        .post("api/d-audit-subject/rule/upload", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          this.$refs["modalUploadImg"].hide();
          this.getRule();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deleteRule: function (rowData, index) {
      this.rowDataRule.splice(index, 1);
      this.Data = rowData;
      // console.log(this.partData);
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-audit-subject/rule/delete", {
          data: {
            subjectId: atob(this.$route.params.subjectId),
            ruleId: this.Data.ruleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.getRule();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
    alertRule(rowData, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRule(rowData, index);
          }
        });
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.putData();
      }
    },
    FormDetail() {
      this.$v.$touch();
      this.submitformDetail = true;
      if (this.$v.formDetail.$invalid != true) {
        this.postRule();
      }
    },
    showModal(data) {
      this.ruleId = data.ruleId;
      this.nameTh = data.nameTh;
      this.$refs["modalUploadImg"].show(this.ruleId);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    getlocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      const arrayBranch = [];
      user.userAccessBranch.forEach((item) => {
        arrayBranch.push(item.branchId);
      });

      this.branchMaster = user.userAccessBranch;
      this.userBranchId = user.branchId;
      this.userBranch = arrayBranch;
      // this.totalRows = this.rows.total;
    },

    delete: function (rowData, index) {
      this.rowDataRule.splice(index, 1);
      this.Data = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/d-audit-subject/rule/delete", {
          data: {
            subjectId: atob(this.$route.params.subjectId),
            ruleId: this.Data.ruleId,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.showDfi();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData, index);
          }
        });
    },
    /**
     * Search the table data with search input
     */
    checkType(data) {
      let name = "";
      if (data == "SALE") {
        name = "หลังการขาย";
      }
      return name;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    showDfi() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-audit-subject/show", {
          params: {
            subjectId: atob(this.$route.params.subjectId),
          },
        })
        .then((response) => {
          // console.log("Show =>", response.data);
          this.form.subjectCode = response.data.data.subjectCode;
          this.form.nameTh = response.data.data.nameTh;
          this.form.nameEn = response.data.data.nameEn;
          this.form.type = {
            nameTh: this.checkType(response.data.data.type),
            id: response.data.data.type,
          };
          this.getBranchDFI();
          this.getRule();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getBranchDFI() {
      this.overlayFlag = true;
      this.getlocalData();
      this.branchMasterSelect = this.branchMaster;
      useNetw
        .get("api/d-audit-subject/branch", {
          params: {
            subjectId: atob(this.$route.params.subjectId),
          },
        })
        .then((response) => {
          // console.log("Branch D F I  =>", response.data.branch);
          this.rowsBranchDfi = response.data.branch;

          this.getlocalData();
          this.branchMasterSelect = this.branchMaster;

          this.branchMasterSelect.forEach((branch, indexBranch) => {
            this.rowsBranchDfi.forEach((item) => {
              if (
                branch.branchId == item.branchId ||
                branch.nameTh == item.branchNameTh
              ) {
                delete this.branchMasterSelect[indexBranch];
                // this.branchMaster.splice(
                //   indexBranch,
                //   1
                // );
              }
            });
          });

          // if(!arr.includes(branch.branchId)){
          //      selectBranch.push(branch);
          //      console.log('selectBranch=>',selectBranch);
          //    }

          // console.log("non select arr", this.branchMaster);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getRule: function () {
      // this.overlayFlag = true;
      this.overlayFlag = true; //skeleton true
      useNetw
        .get("api/d-audit-subject/rule", {
          params: {
            subjectId: atob(this.$route.params.subjectId),
          },
        })
        .then((response) => {
          this.rowDataRule = response.data.rules;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    addAllBranch() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-audit-subject/branch/store-all", {
          subjectId: atob(this.$route.params.subjectId),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    delAllBranch() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-audit-subject/branch/delete-all", {
          data: {
            subjectId: atob(this.$route.params.subjectId),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    delBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-audit-subject/branch/delete", {
          data: {
            subjectId: atob(this.$route.params.subjectId),
            branchId: branch.branchId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    AddBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-audit-subject/branch/store", {
          subjectId: atob(this.$route.params.subjectId),
          branchId: branch.branchId,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    putData: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/d-audit-subject/update", {
          subjectId: atob(this.$route.params.subjectId),
          subjectCode: this.form.subjectCode,
          nameTh: this.form.nameTh,
          nameEn: this.form.nameEn,
          manageBranch: this.form.manageBranch,
          type: this.form.type.id,
        })
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          // this.$router.push({
          //   name: "edit-prospectCustomer",
          //   params: {
          //     ppCusId: btoa(response.data.ppCusId),
          //   },
          // });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
.input-placeholder {
  font-size: 12px;
}
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>
